<template>
  <v-main class="pt-0">
    <v-card flat class="mx-auto ma-6" max-width="1200" min-width="400">
      <v-row>
        <!--   таблица с выбранной номеклатурой -->
        <v-col class="my-4" :style="style.neocard">
          <div class="d-flex flex-row justify-center">
            <div class="mx-5">
              <v-icon size="15" color="grey darken-4" class="mr-1"
                >fas fa-circle</v-icon
              >продано
            </div>
            <div class="mx-5">
              <v-icon size="15" color="blue darken-4" class="mr-1"
                >fas fa-circle</v-icon
              >остаток
            </div>
            <div class="mx-5">
              <v-icon size="15" color="grey" class="mr-1">fas fa-circle</v-icon
              >цена
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th></th>
                  <th style="font-size: 12pt;">артикул</th>
                  <th
                    style="font-size: 11pt;"
                    class="text-left"
                    v-for="shop in shop_info_dict"
                    :key="shop.ID"
                  >
                    {{ shop.ID }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="article in data.reduce(
                    (acc, el) =>
                      ~acc.indexOf(el.article) ? acc : [...acc, el.article],
                    []
                  )"
                  :key="article"
                >
                  <td>
                    <v-img
                      :src="
                        api_data[article]
                          ? api_data[article].imgFrontal.s300x300
                          : not_image
                      "
                      aspect-ratio="2"
                      height="50"
                      width="70"
                      position="center center"
                      :style="style.neocard"
                      @click="
                        api_data[article]
                          ? change_pic_art(api_data[article])
                          : ''
                      "
                    ></v-img>
                  </td>
                  <td style="font-size: 14pt; color: #383F61">
                    <div
                      :class="
                        article == active_article
                          ? 'font-weight-black blueGrey--text'
                          : ''
                      "
                    >
                      {{ article }}
                    </div>
                  </td>
                  <td v-for="el in shop_info_dict" :key="el.email">
                    <div
                      style="cursor: crosshair"
                      @mouseover="mouseover = { sale: true }"
                      @mouseout="mouseover = {}"
                      class="text-16 mb-n2 text-right"
                      :class="
                        !(sale = data.reduce(
                          (acc, el1) =>
                            el1.article == article && el1.email == el.email
                              ? acc + el1.sale
                              : acc,
                          0
                        )) ||
                        (!mouseover.sale && Object.values(mouseover).length)
                          ? 'grey--text text--lighten-4'
                          : 'grey--text text--darken-4'
                      "
                    >
                      {{ sale }}
                    </div>
                    <div
                      style="cursor: crosshair"
                      @mouseover="mouseover = { balance: true }"
                      @mouseout="mouseover = {}"
                      class="text-16 mb-n2 text-right"
                      :class="
                        !(balance = data.reduce(
                          (acc, el1) =>
                            el1.article == article && el1.email == el.email
                              ? acc + el1.balance
                              : acc,
                          0
                        )) ||
                        (!mouseover.balance && Object.values(mouseover).length)
                          ? 'blue--text text--lighten-5'
                          : 'blue--text text--darken-4'
                      "
                    >
                      {{ balance }}
                    </div>
                    <div
                      style="cursor: crosshair"
                      @mouseover="mouseover = { price: true }"
                      @mouseout="mouseover = {}"
                      class="text-16 grey--text text-right"
                      :class="
                        !(price = data.reduce(
                          (acc, el1) =>
                            el1.article == article && el1.email == el.email
                              ? el1.price
                              : acc,
                          0
                        )) ||
                        (!mouseover.price && Object.values(mouseover).length)
                          ? 'grey--text text--lighten-4'
                          : 'grey--text text--darken-1'
                      "
                    >
                      {{ price | money }}
                      <v-icon
                        v-if="price"
                        :color="
                          !mouseover.price && Object.values(mouseover).length
                            ? 'grey lighten-4'
                            : 'grey lighten-2'
                        "
                        size="12"
                      >
                        fas fa-tag
                      </v-icon>
                    </div>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
    </v-card>
  </v-main>
</template>

<script>
import { mapState, mapMutations } from "vuex";

export default {
  name: "ABC_Collection",
  props: ["data", "article", "shop", "shop_info"],
  data: () => ({
    // data:[],
    shop_email: undefined,
    // show_sparkline: true,
    active_article: undefined,
    // value: [2, 5, 0,0,0,0,0,0,0, 2, 5, 9, 0,0,0,0,0,0,0, 5, 10, 3, 5, 0,0,0,0,0,0,0, 0, 0, 1, 8, 2, 9, 0, 5, 0,0,0,0,0,0,0, 2, 5, 9, 0,2, 5, 9, 0, 5, 10],
    model_shops: [],
    mouseover: {}
  }),

  computed: {
    ...mapState(["api_data", "not_image", "style"]),

    articles: function() {
      // артикулы по выбранному магазину
      if (this.data.length == 0) return [];
      let result = this.data.filter(el => el.email == this.shop_email);
      return result;
    },

    shop_info_dict: function() {
      // инфо по магазинам в виде словаря
      if (!this.shop_info || this.shop_info.length == 0) return {};
      return this.shop_info.reduce(
        (acc, el) =>
          ~this.shop.indexOf(el.email) ? { ...acc, [el.email]: el } : acc,
        {}
      );
    }
  },

  methods: {
    ...mapMutations(["change_pic_art"])
  },

  watch: {
    article() {
      // console.log(this.article.article);
      this.shop_email = this.article.email;
      this.active_article = this.article.article;
    }
  },

  created: function() {
    this.shop_email = this.article.email;
    this.active_article = this.article.article;
  }
};
</script>
<stile scoped>
  .neomorph {
  background: #F4F4F4;
  box-shadow: 1px 1px 2px rgba(255, 255, 255, 0.3), -1px -1px 2px rgba(193, 193, 193, 0.5), inset -7px 7px 14px rgba(193, 193, 193, 0.2), inset 7px -7px 14px rgba(193, 193, 193, 0.2), inset -7px -7px 14px rgba(255, 255, 255, 0.9), inset 7px 7px 18px rgba(193, 193, 193, 0.9);
  border-radius: 15px;
  }
</stile>
