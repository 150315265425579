var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-main',{staticClass:"pt-0"},[_c('v-card',{staticClass:"mx-auto ma-6",attrs:{"flat":"","max-width":"1200","min-width":"400"}},[_c('v-row',[_c('v-col',{staticClass:"my-4",style:(_vm.style.neocard)},[_c('div',{staticClass:"d-flex flex-row justify-center"},[_c('div',{staticClass:"mx-5"},[_c('v-icon',{staticClass:"mr-1",attrs:{"size":"15","color":"grey darken-4"}},[_vm._v("fas fa-circle")]),_vm._v("продано ")],1),_c('div',{staticClass:"mx-5"},[_c('v-icon',{staticClass:"mr-1",attrs:{"size":"15","color":"blue darken-4"}},[_vm._v("fas fa-circle")]),_vm._v("остаток ")],1),_c('div',{staticClass:"mx-5"},[_c('v-icon',{staticClass:"mr-1",attrs:{"size":"15","color":"grey"}},[_vm._v("fas fa-circle")]),_vm._v("цена ")],1)])])],1),_c('v-row',[_c('v-col',[_c('v-simple-table',{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th'),_c('th',{staticStyle:{"font-size":"12pt"}},[_vm._v("артикул")]),_vm._l((_vm.shop_info_dict),function(shop){return _c('th',{key:shop.ID,staticClass:"text-left",staticStyle:{"font-size":"11pt"}},[_vm._v(" "+_vm._s(shop.ID)+" ")])})],2)]),_c('tbody',_vm._l((_vm.data.reduce(
                  function (acc, el) { return ~acc.indexOf(el.article) ? acc : acc.concat( [el.article]); },
                  []
                )),function(article){return _c('tr',{key:article},[_c('td',[_c('v-img',{style:(_vm.style.neocard),attrs:{"src":_vm.api_data[article]
                        ? _vm.api_data[article].imgFrontal.s300x300
                        : _vm.not_image,"aspect-ratio":"2","height":"50","width":"70","position":"center center"},on:{"click":function($event){_vm.api_data[article]
                        ? _vm.change_pic_art(_vm.api_data[article])
                        : ''}}})],1),_c('td',{staticStyle:{"font-size":"14pt","color":"#383F61"}},[_c('div',{class:article == _vm.active_article
                        ? 'font-weight-black blueGrey--text'
                        : ''},[_vm._v(" "+_vm._s(article)+" ")])]),_vm._l((_vm.shop_info_dict),function(el){return _c('td',{key:el.email},[_c('div',{staticClass:"text-16 mb-n2 text-right",class:!(_vm.sale = _vm.data.reduce(
                        function (acc, el1) { return el1.article == article && el1.email == el.email
                            ? acc + el1.sale
                            : acc; },
                        0
                      )) ||
                      (!_vm.mouseover.sale && Object.values(_vm.mouseover).length)
                        ? 'grey--text text--lighten-4'
                        : 'grey--text text--darken-4',staticStyle:{"cursor":"crosshair"},on:{"mouseover":function($event){_vm.mouseover = { sale: true }},"mouseout":function($event){_vm.mouseover = {}}}},[_vm._v(" "+_vm._s(_vm.sale)+" ")]),_c('div',{staticClass:"text-16 mb-n2 text-right",class:!(_vm.balance = _vm.data.reduce(
                        function (acc, el1) { return el1.article == article && el1.email == el.email
                            ? acc + el1.balance
                            : acc; },
                        0
                      )) ||
                      (!_vm.mouseover.balance && Object.values(_vm.mouseover).length)
                        ? 'blue--text text--lighten-5'
                        : 'blue--text text--darken-4',staticStyle:{"cursor":"crosshair"},on:{"mouseover":function($event){_vm.mouseover = { balance: true }},"mouseout":function($event){_vm.mouseover = {}}}},[_vm._v(" "+_vm._s(_vm.balance)+" ")]),_c('div',{staticClass:"text-16 grey--text text-right",class:!(_vm.price = _vm.data.reduce(
                        function (acc, el1) { return el1.article == article && el1.email == el.email
                            ? el1.price
                            : acc; },
                        0
                      )) ||
                      (!_vm.mouseover.price && Object.values(_vm.mouseover).length)
                        ? 'grey--text text--lighten-4'
                        : 'grey--text text--darken-1',staticStyle:{"cursor":"crosshair"},on:{"mouseover":function($event){_vm.mouseover = { price: true }},"mouseout":function($event){_vm.mouseover = {}}}},[_vm._v(" "+_vm._s(_vm._f("money")(_vm.price))+" "),(_vm.price)?_c('v-icon',{attrs:{"color":!_vm.mouseover.price && Object.values(_vm.mouseover).length
                          ? 'grey lighten-4'
                          : 'grey lighten-2',"size":"12"}},[_vm._v(" fas fa-tag ")]):_vm._e()],1)])})],2)}),0)]},proxy:true}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }